
































import {Component, Prop, Vue} from 'vue-property-decorator';

/**
 * This component represents a simple, versatile "side bar" implemented as a vuetify card that can be hidden off the
 * view port and unhidden programmatically using the showSideCard prop. The component may request to be closed
 * by its parent using the close-side-card event. Parents should normally react to this event
 * by setting the showSideCard prop false.
 */
@Component({
  components: {},
})
export default class SideCardComponent extends Vue {
  /**
   * Determines the title of the card. Default is empty.
   */
  @Prop({default: ''})
  public cardTitle!: string;
  /**
   * Decides whether or not the card is shown.
   */
  @Prop({default: false})
  public showSideCard!: boolean;
  /**
   * Decides whether or not the object should be deletable
   */
  @Prop({default: false})
  public deletable!: boolean;
  /**
   *  Decides whether or not the object should be editable
   */
  @Prop({default: false})
  public editable!: boolean;
  /**
   *  Decides whether or not the object should be copyable
   */
  @Prop({default: false})
  public copyable!: boolean;
}
